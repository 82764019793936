.App {
  max-width: 90%;
  margin: 0 auto;
  font-size: 16px;
}

.markdown {
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: attr(width) / attr(height);
  }
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.section-title {
  margin-top: 30px;
}

.card {
  width: calc(12% - 10px);
  margin: 0;
}

.card-link {
  color: inherit;
  text-decoration: none;
}

.card-image {
  width: 100%;
}

.channel-image {
  border-radius: 100%;
  padding: 0, 10px, 0, 0;
  width: 40px;
  height: 40px;
}

.card-title {
  margin-top: 10px;
  margin-bottom: 0;
}

.card-channel {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

/* Tablets */
@media (max-width: 1000px) {
  .App {
    max-width: 600px;
  }

  .card {
    width: calc(50% - 22px);
  }
}

/* Mobiles */
@media (max-width: 640px) {
  .App {
    max-width: 100%;
    padding: 0 15px;
  }

  .card {
    width: 100%;
  }
}